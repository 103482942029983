<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                        class="mt-1"
                        btnTitle="Go Back"
                        title="Create Payment"
                        @onClickCloseButton="goToList"
                />

                <div class="row mt-1">

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Voucher Date</label>
                        <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="payment.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Paid from: </label>
                        <v-select
                                placeholder="Select Cash & Bank Account"
                                class="w-100"
                                v-model="credit.account_head_id"
                                :options="cashAndBack"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Payment Ref</label>
                        <input
                                type="text"
                                class="form-control invoice-edit-input"
                                placeholder="Payment Reference"
                                v-model="payment.payment_ref">
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Payment Ref Date </label>
                        <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="payment.payment_ref_date"
                        >
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Project</label>
                        <v-select
                                placeholder="Select Project"
                                v-model="credit.project_id"
                                :options="projects"
                                label="name"
                                :reduce="name => name.id"
                                @option:selected="onSelectProject(true)"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Donors</label>
                        <v-select
                                placeholder="Select Donor"
                                class="w-100"
                                :options="donors"
                                label="name"
                                :reduce="name => name.id"
                                v-model="credit.donor_id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Cost Centre</label>
                        <v-select
                                placeholder="Select Cost Centre"
                                v-model="credit.cost_centre_id"
                                :options="costCentres"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                      <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Party: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                                  <i class="fas fa-search"></i>
                              </span>
                      </div>

                      <AsyncSelect
                          placeholder="Select Party"
                          v-model="contactProfile"
                          :api-service="fetchContactProfiles"
                          :format-label="formatPatientLabel"
                      />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Business</label>
                        <v-select
                                placeholder="Select Business"
                                class="w-100"
                                :options="businesses"
                                label="name"
                                :reduce="name => name.id"
                                v-model="payment.business_id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">PR / PO Reference</label>
                        <div class="input-group mb-3">
                            <input v-model="payment.txn_no" type="text" class="form-control" placeholder="PR / PO Referance">
                            <button @click="openPrPoModal()" class="btn btn-outline-secondary" type="button" id="button-addon2">Select</button>
                        </div>
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Tag Register</label>
                        <AsyncSelect
                            placeholder="Select Tag Register"
                            v-model="tagRegister"
                            :api-service="fetchTagRegisterList"
                            :format-label="formatPatientLabel"
                        />
                    </div>
                </div>
            </div>
            <div class="px-2 mt-2">
                <AddFormElement
                        class="mb-2"
                        :accountHeads="accountHeads"
                        :ngoabHeads="ngoabHeads"
                        :budgets="budgets"
                        :programs="programs"
                        :projects="projects"
                        :costCentres="costCentres"
                        :contactProfiles="contactProfiles"
                        :businesses="businesses"
                        :paymentDate="payment.date"
                        :fd6-donors="fd6Donors"
                        v-for="(item, index) in payment.general_ledgers"
                        :key="index"
                        :index="index"
                        :item="item"
                        @onClose="onClose"
                        @isLoading="isDataLoading = true"
                        @loaded="isDataLoading = false"
                        @onClickToApplyTdsVds="() => onClickToApplyTdsVds(item)"
                />

                <div class="row me-1 mt-3">
                    <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end" :value="totalDebit"></div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="attachment" class="form-label fw-bold">Attachment</label>
                            <BlobFileViewer :attachments="payment.attachments" />
                            <FileUpload
                                    :is-drag-active="true"
                                    button-text="Upload Documents"
                                    v-model="payment.attachments"
                            >
                            </FileUpload>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="payment.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="my-2 text-center">
                    <hr class="mb-1">
                    <button
                            :disabled="paymentLoading"
                            @click="savePayment(true)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="paymentLoading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save
                    </button>
                    <button
                            :disabled="saveNewLoader"
                            @click="savePayment(false)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & New
                    </button>
                    <button
                            :disabled="saveNewLoader"
                            @click="savePayment(true, true)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & Print
                    </button>
                    <button
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <!-- PR PO Modal -->
            <PrPoModal
                :lists="prPoLists"
                ref="prPoModal"
                @selectedPrPo="selectedPrPo"
            />
            <ContactDropdownFilter
                v-if="store.state.isModalOpenThree"
                @onSearchContact="onSearchContact"
                :isFilterable="true"
                :companyRoles="companyRoles"
            />
            <Loader v-if="isDataLoading"/>

            <PaymentTdsVdsModal
                :data="itemDetails"
                :tdsRateList="tdsRateList"
                :vdsRateList="vdsRateList"
                @onSaveTdsVds="onSaveTdsVds"
                v-if="$store.state.isModalOpen"
                :tdsPayableHeads="tdsPayableHeads"
                :vdsPayableHeads="vdsPayableHeads"
                :accountHeads="accountHeads"
            />

        </div>
    </div>
</template>

<script>
import handlePayment from '@/services/modules/payment'
import {inject} from 'vue'
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/ngo/payment/AddFormElement'
import PaymentTdsVdsModal from '@/components/molecule/ngo/payment/PaymentTdsVdsModal'
import PrPoModal from '@/components/molecule/ngo/payment/PrPoModal'
import AddButton from '@/components/atom/AddButton'
import handleContact from '@/services/modules/contact'
import handleNGOPrograms from "@/services/modules/ngo/program";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleActivities from "@/services/modules/ngo/activity";
import FileUpload from "@/components/atom/FileUpload";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import handleProjects from "@/services/modules/procurement/project";
import Loader from '@/components/atom/LoaderComponent'
import handleFD6Projects from "@/services/modules/procurement/fd6";
import handleCBusinesses from '@/services/modules/businesses'
import handleTagRegister from '@/services/modules/scm/tag-register'
import figureFormatter from "@/services/utils/figureFormatter";
import handleJournal from '@/services/modules/journal'
import pdfPrinterHelper from '@/services/utils/pdfPrinterHelper';
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import {useStore} from "vuex";
import handleRole from "@/services/modules/role";
import handleTdsVds from '@/services/modules/procurement/tdsVds'
import handlePurchase from '@/services/modules/purchase'
import { generateUUID } from '@/services/utils/global'
import { cloneDeep } from 'lodash'
import handlePurchaseAndSales from "@/services/modules/purchase";
import { usePayment } from "@/services/modules/purchase/payment";

const { fetchTdsRateList, fetchVdsRateList } = handleTdsVds();
const { getAccountHeadBySlag } = handlePurchase()
const { storeTdsVdsPayment } = handlePurchaseAndSales();
const { formatPaymentTransactions } = usePayment();

export default {
    name: 'PaymentCreate',
    components: {
        ContactDropdownFilter,
        PaymentTdsVdsModal,
        AsyncSelect,
        BlobFileViewer,
        FileUpload,
        TitleButton,
        AddFormElement,
        PrPoModal,
        AddButton,
        Loader
    },
    data: () => ({
        cashAndBack: [],
        accountHeads: [],
        tagRegister : null,
        contactProfile : null,
        contactProfiles : [],
        companyRoles: [],
        projects : [],
        programs : [],
        costCentres : [],
        businesses : [],
        tagRegisters : [],
        prPoLists : [],
        activities : [],
        budgets : [],
        budgetHeads : [],
        ngoabHeads : [],
        fd6Donors : [],
        donors : [],
        saveNewLoader: false,
        isDataLoading: false,
        credit: {
            account_head_id: null,
            project_id: null,
            donor_id: null,
            cost_centre_id: null,
            contact_profile_id: null,
            tag_register_id: null,
        },
        payment: {
            mop_account_head_id:'',
            company_id:'',
            contact_profile_id: null,
            project_id : null,
            program_id : null,
            donor_id: null,
            cost_centre_id: null,
            business_id: null,
            txn_no: null,
            date: '',
            note: '',
            mop_references: 'mop',
            payment_ref : '',
            payment_ref_date : '',
            attachments: [],
            voucher_type: 'payment_voucher',
            general_ledgers: [
                {
                    account_head_id: null,
                    ngoab_budget_id: null,
                    budget_head_id: null,
                    program_id: null,
                    project_id: null,
                    activity_id: null,
                    cost_centre_id: null,
                    tag_register_id: null,
                    business_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                }
            ]
        },
        paymentRes: {},
        itemDetails: null,
        tdsRateList: [],
        vdsRateList: [],
        tdsPayableHeads: [],
        vdsPayableHeads: [],
        paymentMaster: null
    }),
    computed: {
        isLoading () {
            return this.paymentLoading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.payment.general_ledgers
        },
        totalDebit () {
            let total = 0;
            this.general.map(i => {
                if(i.debit) total += i.debit
            })
            return total
        },
        totalNetPayable () {
            return this.general.reduce((total, item) => total + (item.net_payable_amount || 0), 0);
        }
    },

    methods: {
        onClickToApplyTdsVds (item) {
            this.itemDetails = item;
            this.$store.state.isModalOpen = true;
        },
        async getAccountHeads (slug) {
            await getAccountHeadBySlag(slug, this.getQuery()).then(res => {
                if(! res.status) return;
                this.setAccountHeads(res.data, slug);
            })
        },
        setAccountHeads (data, slug){
            if(slug === 'tds_payable') {
                this.tdsPayableHeads = data;
            }

            if(slug === 'vds_payable') {
                this.vdsPayableHeads = data;
            }
        },
        getQuery () {
            let query = '?company_id=' + this.$route.params.companyId;
            return query;
        },
        async getTdsRates () {

            await fetchTdsRateList(this.getQuery()).then(res => {
                if(res.status){
                    this.tdsRateList = res.data;
                }
            }).catch(err => {
                console.log(err)
            })
        },
        async getVdsRates () {

            await fetchVdsRateList(this.getQuery()).then(res => {
                if(res.status){
                    this.vdsRateList = res.data;
                }
            }).catch(err => {
                console.log(err)
            })
        },
        onSaveTdsVds (data) {
            for(let key in data) {
                this.itemDetails[key] = data[key];
            }
        },
        selectedPrPo(pr_po) {
          this.payment.txn_no = pr_po;
        },
        openPrPoModal() {
          this.$refs.prPoModal.toggleModal()
        },

        goToPrint(id) {
            const companyQuery = `?company_id=${this.$route.params.companyId}`;
            this.fetchSinglePayment(id, companyQuery).then( (res) => {
            if(res){
                this.paymentRes = res.data;
            }}).then (() => {
                this.generatePdf(this.$route.params.companyId, this.paymentRes, 'payment')
            }).catch((err) => {
                console.log('Something went wrong');
            })
        },
        formatDate(date) {
            var formattedDate = date.toISOString().split('T')[0];
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var amOrPm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
            return this.formatToDdMmYy(formattedDate) + ' ' + formattedTime
        },

        formatToDdMmYy(date) {
            var dat = date.split('-');
            return `${dat[2]}-${dat[1]}-${dat[0]}`;
        },
        setVoucherNumberAndDate(withoutDate = false) {
            new Promise(async (resolve, reject) => {
                try {
                    if(! withoutDate) {
                        this.payment.date = new Date().toISOString().split('T')[0]
                    }
                    resolve();
                } catch (err) {
                    reject(err);
                }
            })

        },
        goToList() {
            this.$router.push({name: 'ngo-payment-voucher',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }

            })
        },
        onClose(index) {
            this.payment.general_ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.payment.general_ledgers.push({
                account_head_id: null,
                debit: null,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        async resetForm() {
            this.payment = {
                mop_account_head_id:'',
                company_id:'',
                date: '',
                note: '',
                mop_references: 'mop',
                voucher_type: 'payment_voucher',
                general_ledgers: [
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    },
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    }
                ]
            }
            await this.setVoucherNumberAndDate()
        },

        getTdsVdsLedgers (accountHeadId, creditAmount, uid, label, contactProfileId = null) {
            return {
                id: null,
                account_head_id: accountHeadId,
                contact_profile_id: contactProfileId,
                uid: uid,
                debit: 0,
                credit: creditAmount,
                taxable_amount: null,
                tax_rate: null,
                note: null,
                label: label
            };
        },

        getFormData () {
            let formData = new FormData();
            if(!this.payment.contact_profile_id) this.payment.contact_profile_id = ''
            if(!this.payment.txn_no) this.payment.txn_no = ''
            Object.keys(this.payment).forEach(i => {
                if(i !== 'general_ledgers') {
                    formData.append(i, this.payment[i])
                }
            })
            let generalLedgerData = cloneDeep(this.general).filter(ledger => ledger.account_head_id && ledger.debit);
            let tagRegisterId = this.tagRegister ? this.tagRegister.id : null;
            let mop_account_head_id = this.credit.account_head_id;
            const paymentGenerals = [];
            const tdsVdsLedgers = [];

            for (const generalLedger of generalLedgerData) {
                generalLedger.tag_register_id = tagRegisterId;
                generalLedger.mop_account_head_id = mop_account_head_id;
                generalLedger.uid = generateUUID();
                paymentGenerals.push(generalLedger);

                if(generalLedger.tds_amount) {
                    const tdsLedger = this.getTdsVdsLedgers(generalLedger.tds_account_head_id, generalLedger.tds_amount, generalLedger.uid, 'tds_ledger', generalLedger.contact_profile_id);
                    tdsVdsLedgers.push(tdsLedger);
                }
 
                if(generalLedger.vds_amount) {
                    const tdsLedger = this.getTdsVdsLedgers(generalLedger.vds_account_head_id, generalLedger.vds_amount, generalLedger.uid, 'vds_ledger', generalLedger.contact_profile_id);
                    tdsVdsLedgers.push(tdsLedger);
                }

                if(generalLedger.net_payable_amount) {
                    generalLedger.debit = generalLedger.total_amount;
                }
            }

            const ledgerMaster = {
                account_head_id: this.credit.account_head_id,
                project_id: this.credit.project_id,
                donor_id: this.credit.donor_id,
                cost_centre_id: this.credit.cost_centre_id,
                contact_profile_id: this.contactProfile ? this.contactProfile.id : null,
                business_id: this.payment.business_id,
                tag_register_id: tagRegisterId,
                debit: null,
                credit: this.totalNetPayable ? this.totalNetPayable : this.totalDebit,
                note: this.payment.note,
                tax_rate: null,
                taxable_amount: null
            };

            this.paymentMaster = cloneDeep(ledgerMaster);
            this.paymentMaster.payment_generals = paymentGenerals;

            generalLedgerData.push(...tdsVdsLedgers);
            generalLedgerData.push(ledgerMaster);
            
            formData.append('general_ledgers', JSON.stringify(generalLedgerData))

            if(this.payment.attachments) {
                for(let i=0; i<this.payment.attachments.length; i++) {
                    let key = `attachments[${i}]`;
                    let value = this.payment.attachments[i];
                    formData.append(key, value);
                }
            }

            return formData;
        },

        async savePayment (redirect = false, print = false) {

            if(! this.credit.account_head_id) {
                this.showError('Please select an account head')
                return;
            }

            this.payment.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            
            if(redirect) {
                this.paymentLoading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createPayment(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    await this.resetForm()

                    this.savePayments(res.data);

                    if(redirect && print) this.goToPrint(res.data.id)
                    
                    if(redirect){
                        this.$router.push({
                            name: 'ngo-payment-voucher',
                            params: {
                              companyId: this.$route.params.companyId,
                              moduleId: this.$route.params.moduleId,
                              menuId: this.$route.params.menuId,
                              pageId: this.$route.params.pageId,
                            },
                            query: {
                                start: this.start,
                                end: this.end}
                        })
                    }
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                    return ;
                }

                if(err.response && err.response.status !== 406) {
                    this.showError(err.response?.data.message)
                }
            } finally {
                this.paymentLoading = false
                this.saveNewLoader = false
            }
        },

        async savePayments (ledger) {
            const data = formatPaymentTransactions(this.paymentMaster, this.payment, this.$route.params.companyId, ledger);
            
            await storeTdsVdsPayment(data).then(res => {
                if(res.status) {
                    // TODO: add some logic here
                }
            })
        },

        onSelectProject(resetDonor = false) {
            this.donors = [];
            if(resetDonor) this.credit.donor_id = null;
            for (let project of this.projects) {
                if(project.id === this.credit.project_id && project.party.hasOwnProperty('id')){
                    this.donors.push(project.party);
                    break;
                }
            }
        },

        onOpenContactSearchModal () {
          this.$store.state.isModalOpenThree = true;
        },
        async getCompanyRoles () {
          const res = await this.fetchCompanyDefaultRoles(`?company_id=${this.$route.params.companyId}`);
          if (res.status) {
            this.companyRoles = res.data
          }
        },
        onSearchContact (contact) {
          this.contactProfile = contact
        },
    },
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const {decimalFormat} = figureFormatter();
        const store = useStore()
        const {
            createPayment,
            fetchCashAndBank,
            paymentLoading,
            fetchSinglePayment
        } = handlePayment()

        const {
            fetchAccountHeadsList
        } = handleJournal()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchProgramList,
        } = handleNGOPrograms()

        const {
          fetchCostCentreList,
        } = handleCostCentres()


        const {
          fetchActivityList,
        } = handleActivities()

        const {
            fetchProjects,
        } = handleProjects()

        const {
            fetchAllFD6Donors,
            fetchFD6AccountsAll
        } = handleFD6Projects()
        const { formatPatientLabel } = useAsyncDropdownHelper();
        const { fetchCompanyDefaultRoles } = handleRole();
        const {fetchBusinessList} = handleCBusinesses()
        const {fetchTagRegisterList, fetchPrPoList} = handleTagRegister()
        const { generatePdf } = pdfPrinterHelper();

        return {
            store,
            fetchAccountHeadsList,
            createPayment,
            fetchCashAndBank,
            fetchContactProfiles,
            paymentLoading,
            showError,
            showSuccess,
            fetchProgramList,
            fetchCostCentreList,
            fetchActivityList,
            fetchProjects,
            fetchAllFD6Donors,
            fetchFD6AccountsAll,
            fetchBusinessList,
            fetchTagRegisterList,
            fetchPrPoList,
            decimalFormat,
            fetchSinglePayment,
            formatPatientLabel,
            fetchCompanyDefaultRoles,
            generatePdf
        }
    },

    async mounted() {
        this.isDataLoading = true;
        let voucher    = null;
        let companyId = this.$route.params.companyId
        let contactId = '';
        let q = '';
        let companyQuery = '?company_id=' + companyId;
        let query = companyQuery + '&q=' + q + '&contact_id' + contactId;
        let projectQuery = companyQuery + '&with_donors=1';
        let accountHeadQuery = companyQuery + '&cash_bank_hide=yes&status=active';
        Promise.all([

            this.fetchAccountHeadsList(accountHeadQuery).then(res => {
                if(res.data){
                    this.accountHeads = res.data
                }
            }),
            this.fetchFD6AccountsAll(companyQuery).then(res => {
                if(res.data){
                    this.ngoabHeads = res.data
                }
            }),
            this.fetchProgramList(companyQuery).then(res => {
                if(res.data){
                    this.programs = res.data;
                }
            }),
            this.fetchCostCentreList(companyQuery).then(res => {
                if(res.data) {
                    this.costCentres = res.data
                }
            }),
            this.fetchActivityList(companyQuery).then(res => {
                if(res.data){
                    this.activities = res.data
                }
            }),
            this.fetchProjects(projectQuery).then(res => {
                if(res.data) {
                    this.projects = res.data
                }
            }),
            this.fetchCashAndBank(companyId).then(res => {
                if(res.data){
                    this.cashAndBack = res.data
                }
            }),
            this.fetchContactProfiles(query).then(res => {
                if(res.data) {
                    this.contactProfiles = res.data;
                }
            }),
            this.fetchBusinessList(companyQuery).then(res => {
                if(res.data) {
                    this.businesses = res.data;
                }
            }),
            this.fetchPrPoList(companyQuery).then(res => {
                if(res.data) {
                    this.prPoLists = res.data;
                }
            }),
            this.setVoucherNumberAndDate(),
            this.fetchAllFD6Donors(companyQuery).then(res => {
                if (res.status) {
                    this.fd6Donors = res.data
                }
            }),
            this.getAccountHeads('tds_payable'),
            this.getAccountHeads('vds_payable'),
            this.getTdsRates(),
            this.getVdsRates()
        ]).then(() => {
            this.isDataLoading = false;
        }).catch(err => {
            this.isDataLoading = false;
            this.showError(err.message)
        }).finally(() => {
            this.isDataLoading = false;
        })

    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>
